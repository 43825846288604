.app-search {
	position: absolute;
	top: calc(50% - 61px);
	left: calc(50% - 250px);

	z-index: 1000;

	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 80%;
	width: 500px;

	&-header {
		font-size: 40px;
		margin-bottom: 20px;
		text-shadow: 0 4px 4px black;
		user-select: none;
		cursor: default;
	}

	&-bar {
		color: white;

		width: calc(100% - 50px);
		height: 25px;
		max-height: calc(100% -  (100% - 45px));

		box-shadow: 0 0 4px 0 black;
		border-radius: 50px;
		border: 1px solid white;
		background: rgba(0,0,0,0.5);
		padding: 10px 25px;

		&:focus {
			outline: none;
		}
	}
}