.bookshelf {
  position: absolute;
  left: 0;
  top: 0;

  overflow: hidden;
  z-index: -1;

  width: 100%;
  height: 100%;

  .compartment {
    height: 130px;
    width: 100%;

    .book-container {
      display: flex;
      height: 120px;
      width: 100%;
      overflow: hidden;
    }

    .plate {
      height: 10px;
      width: 100%;
      background: saddlebrown;
    }
  }
}