.book-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &--horizontal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &--vertical {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}